<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h4>{{$t('Pump Settings')}}</h4>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" order="1"> 
            <h5>{{$t('Timeouts')}}</h5>
            <v-col>
              <v-text-field
              dense
               outlined
               :loading="loading"
               number
               :label="$t('General timeout')"
               v-model.number="setting.general_fsm_timeout"
               :suffix="$t('secs')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-row  >
                <v-row>
                  <v-col>
                    <v-switch
                      :label="$t('Enable flow protection')"
                      :true-value="1"
                      :false-value="0"
                      v-model="setting.flow_protection"
                      color="#196619"
                      class="mx-3 my-2"
                    ></v-switch>
                  </v-col>
                  <v-col>
                    <v-text-field
                    dense
                    outlined
                    :loading="loading"
                    number
                    :label="$t('Flow protection timeout')"
                    v-model.number="setting.flow_protection_timeout"
                    :suffix="$t('secs')"
                    class="mx-n3"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col >
                  <v-text-field
                  outlined
                  :loading="loading"
                  dense
                  number
                  :label="$t('Suspend timeout')"
                  v-model.number="setting.suspend_timeout"
                  :suffix="$t('secs')"
                  ></v-text-field>
                </v-col>
                <v-col >
                  <v-text-field
                  outlined
                  :loading="loading"
                  dense
                  number
                  :label="$t('Time to suspend')"
                  v-model.number="setting.timeout_to_suspend"
                  :suffix="$t('secs')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-col >
          <v-col cols="12" md="4" order="2"> 
            <h5>{{$t('Measurement settings')}}</h5>
            <v-col>
              <v-text-field
              dense
               outlined
               :loading="loading"
               number
               :label="$t('Pulses per litre')"
               v-model.number="setting.pulser_ppl"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
              dense
               outlined
               :loading="loading"
               number
               :label="$t('Money limit')"
               v-model.number="setting.money_limit"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
              dense
               outlined
               :loading="loading"
               type="number"
               :label="$t('Decimal point')"
               v-model.number="setting.decimal_point"
              ></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="12" md="4" order="3">
            <h5>{{$t('Special settings')}}</h5>
            <v-col>
              <v-switch
                 :label="$t('Virtual vehicle')"
                 :true-value="1"
                 :false-value="0"
                 v-model="setting.virtual_vehicle"
                 color="#196619"
                >
              </v-switch>
            </v-col>
            <v-col>
            <v-autocomplete
              outlined
              dense
              v-model="setting.virtual_vehicle_string"
              :items="entries"
              :loading="isLoading"
              :search-input.sync="search"
              color="primary"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="user_data1"
              :label="$t('Select virtual vehicle')"
              placeholder="Start typing to Search"
              prepend-icon="mdi-database-search"
            ></v-autocomplete>
            </v-col>
            <v-col>
              <v-switch
                 :label="$t('Remote Driver Auth')"
                 :true-value="1"
                 :false-value="0"
                 v-model="setting.not_installed_vehicles"
                 color="#196619"
                >
              </v-switch>
            </v-col>
          </v-col>
          
        </v-row>
         <v-row>
            <v-col >
            <v-row>
              <v-col >
                <v-switch
                 :label="$t('Virtual inuse')"
                 v-model="setting.virtual_inuse"
                 color="#196619"
                 :true-value="1"
                 :false-value="0"
                >
                </v-switch>
                <v-switch
                 :label="$t('Nozzle inversion')"
                 v-model="setting.nozzle_inversion"
                 color="#196619"
                 :true-value="1"
                :false-value="0"
                >
                </v-switch>
                <v-switch
                 :label="$t('Single operation')"
                 v-model="setting.single_operation"
                 color="#196619"
                 :true-value="1"
                :false-value="0"
                >
                </v-switch>
                <v-switch
                 :label="$t('Use API to get Limits')"
                 v-model="setting.use_serverapi_limits"
                 color="#196619"
                 :true-value="1"
                :false-value="0"
                >
                </v-switch>
              </v-col>
              <v-col >
                <v-switch
                 :label="$t('Inventory (tax purposes)')"
                 v-model="setting.inventory_menu"
                 color="#196619"
                 :true-value="1"
                :false-value="0"
                >
                </v-switch>
                <v-switch
                 :label="$t('Bartec data required')"
                 v-model="setting.bartec_data_required"
                 color="#196619"
                 :true-value="2"
                 :false-value="0"
                 :disabled="setting.printer == 1"
                 @change="setBartec"
                >
                </v-switch>
                <v-switch
                  v-if="setting.bartec_data_required == 2"
                 :label="$t('Bartec old communication')"
                 v-model="setting.bartec_dialog"
                 color="#196619"
                 :true-value="1"
                 :false-value="0"
                 :disabled="setting.bartec_data_required == 0"
                >
                </v-switch>
                <v-switch
                 :label="$t('Printer')"
                 v-model="setting.printer"
                 color="#196619"
                 :true-value="1"
                 :false-value="0"
                 :disabled="setting.bartec_data_required == 2"
                 @change="setPrinter"
                >
                </v-switch>

              </v-col>
              <v-col >
                <v-switch
                 :label="$t('Manual Input')"
                 v-model="setting.manual_input"
                 color="#196619"
                 :true-value="1"
                :false-value="0"
                >
                </v-switch>
                <v-switch
                 :label="$t('Manual Km input')"
                 v-model="setting.manual_input_km"
                 color="#196619"
                 :true-value="1"
                 :false-value="0"
                 :disabled="setting.manual_input == 0"
                >
                </v-switch>
                <v-switch
                 :label="$t('Manual note input')"
                 v-model="setting.manual_input_rem"
                 color="#196619"
                 :true-value="1"
                 :false-value="0"
                 :disabled="setting.manual_input == 0"
                >
                </v-switch>
              </v-col>
              <v-col >
                <v-switch
                 :label="$t('Show totalizer')"
                 v-model="setting.show_info"
                 color="#196619"
                 :true-value="1"
                :false-value="0"
                >
                </v-switch>
                <v-switch
                 :label="$t('Compensation 15˚C')"
                 v-model="setting.compensated_input"
                 color="#196619"
                 :true-value="1"
                :false-value="0"
                >
                </v-switch>
                <v-switch
                 :label="$t('Use 1.pulser for all inputs')"
                 v-model="setting.one_pulser"
                 color="#196619"
                 :true-value="1"
                :false-value="0"
                >
                </v-switch>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$router.push('/pumps')"  min-width="150">{{$t('Cancel')}}</v-btn>
        <v-btn  dark color="#196619" @click="saveSettings" min-width="200">{{$t('Save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name:'PumpSettings',
  props: ['value'],
  data() {
    return {
      loading: false,
      pump: null,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      setting: null,
      newRecord: false


    }
  },
  methods: {
    async initialize(id) {
      let res = null;
      let ent = null;
      this.loading = true
      try {
        res = await  this.$http.get(`/setting/${id}`)
        this.setting = res.data
        if (this.setting?.virtual_vehicle_string?.length > 0) {
          const ent = await this.$http.get("/vehicle", { params: { search: this.setting.virtual_vehicle_string}})
          this.entries = ent.data
        }
        this.loading = false
      } catch (e) {     
        console.log(e.response.data)
        if(e.response.status === 404) {
          this.setting = e.response.data.data
          this.newRecord = true
        } else {
          this.$emit('alertMsg', { type: 'error', text: e.message + "\n" + e.response.data?.error + "\n"})
        }

      } finally {
        this.loading = false
      }
    },

    async saveSettings() {
      let res = null;
      try {
        let res = null
        if(this.newRecord) {
          res = await  this.$http.post(`/setting`, this.setting)
        } else {
          res = await  this.$http.put(`/setting`, this.setting)
        }
        this.setting = res.data
        this.loading = false
        this.$router.push('/pumps')
        this.$emit("alertMsg", {
          type: "success",
          text: "Data saved OK",
        });
      } catch (e) {
        console.log(e)
        this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
      } finally {
        this.loading = false
        this.newRecord = false
      }
    },

    setBartec(v) {
      if(v === 2) {
        this.setting.printer = 0
      } else if(v === 0 ) {
        this.setting.bartec_dialog = 0
      }
    },
    setPrinter(v) {
      if(v === 1) {
        this.setting.bartec_data_required = 0
        this.setting.bartec_dialog = 0
      }
    }

    
  },
  computed: {
      
    },
  watch: {
      async search (val) {
        // Items have already been loaded
        //if (this.entries.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        try {
          const res = await this.$http.get("/vehicle", { params: { search: val}})
          this.entries = res.data
          this.isLoading = false
        } catch (e) {
          console.log(e)
        } finally {
          this.isLoading = false
        }

      }
  },
  async created () {
    this.pump = this.$route.params.data
    if(this.pump) {
     await this.initialize(this.pump.id)
    } else {
      this.$router.push('/settings/pumps')
    }
  }
}
</script>